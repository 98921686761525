import { state } from './state';

export const mutations = {
	testMutationss() {
		state.arr = [1, 2, 3, 4, 5];
	},
	setMobileOpenRow(item) {
		state.mobileOpenRow = item;
	}
};
