// Если нам надо оплучить глобальные данные
import { getters } from '@/store/store';
// Если нам надо обработать данные используем мутации
// import { mutations } from './mutations';

export const actions = {
	getMOResultStat(parametrs, year) {
		return new Promise((resolve, reject) => {
			this.$http
				.post(`OS/GetMOResultStat?regionId=${getters.getAuthUser().regionId}&nokYear=${year}`, parametrs)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
};
