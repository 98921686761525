// Если нам надо оплучить глобальные данные
import { getters } from '@/store/store';
// Если нам надо обработать данные используем мутации
import { mutations } from './mutations';

export const actions = {
	getMOResultStat(parametrs) {
		return new Promise((resolve, reject) => {
			this.$http
				.post(`OS/GetControlUserStat?userId=${getters.getAuthUser().id}`, parametrs)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	setMobileOpenRow(item = null) {
		mutations.setMobileOpenRow(item);
		console.log(item);
	},
	getRegionYearData() {
		return new Promise((resolve, reject) => {
			this.$http
				.get(`Region/GetRegionYearData?regionId=${getters.getAuthUser().regionId}`)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
};
