export const state = {
	arr: [],
	mobileOpenRow: {
		// anketaId: '99708bc5-1ec2-4b4f-8747-d9ce5ffca45c',
		// complete: 0,
		// completedQuestions_1_1_1: null,
		// completedQuestions_1_1_2: null,
		// completedQuestions_1_2: null,
		// completedQuestions_3_1: null,
		// completedQuestions_3_2: null,
		// isCompletedQuestions_1_1_1: false,
		// isCompletedQuestions_1_1_2: false,
		// isCompletedQuestions_1_2: false,
		// isCompletedQuestions_3_1: false,
		// isCompletedQuestions_3_2: false,
		// created: '2022-06-01T01:53:07.176716',
		// fullName: null,
		// id: '321cc16d-edda-43f4-a7e7-5d2e1dfd5f03',
		// isComplete: false,
		// isSelected: null,
		// medicalId: '09f67717-86b9-4ebd-bb23-6eca8c95cf44',
		// name: "Государственное бюджетное учреждение здравоохранения Астраханской области 'Медицинский информационно-аналитический центр'",
		// res_1_1_1: 1,
		// res_1_1_2: 1,
		// res_1_2: 1,
		// res_3_1: 1,
		// res_3_2: 1,
		// userId: 'a30c9542-8b2f-4caa-b9f4-c793d1d842f4'
	}
};
